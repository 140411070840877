import { computed, ref, shallowRef, watchEffect } from 'vue';
import { RouteLocationNormalized } from 'vue-router';

import { accountService } from '@exchange/libs/account/service/src';
import { modalService } from '@exchange/libs/modals/src';
import { settingsService } from '@exchange/libs/settings/service/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';
import { checkIsBiometricRoute } from '@exchange/routing';

import GeneralToSModal from './GeneralToSModal.vue';
import TermsAndConditionsModal from './TermsAndConditionsModal.vue';

export const useGlobalConfirmationModals = (route: RouteLocationNormalized) => {
  const modalGeneralToSKey = ref<string>();
  const modalTermAndConditionsKey = ref<string>();

  const shouldTermAndConditionsNotBeShown = computed(() => {
    const verificationDate = accountService.accountVerificationDate.value?.unix;

    if (!verificationDate) {
      return false;
    }

    return Number(verificationDate) * 1000 < 1712880000000; // before '2024-04-12T00:00:00.000Z'
  });

  let showGeneralToSModalPromise = Promise.resolve();
  let showTermAndConditionsModalPromise = Promise.resolve();

  const resolveGeneralToS = () => {
    showGeneralToSModalPromise = Promise.resolve();

    return showGeneralToSModalPromise;
  };
  const hideGeneralToSModal = () => {
    if (modalGeneralToSKey.value) {
      modalService.hide(modalGeneralToSKey.value);
      modalGeneralToSKey.value = undefined;
    }
  };
  const showGeneralToSModal = async () => {
    const { showRiskConfirmation } = settingsService.complianceInfo;

    if (showRiskConfirmation === false) {
      if (modalGeneralToSKey.value) {
        hideGeneralToSModal();
      }

      return resolveGeneralToS();
    }

    if (showRiskConfirmation === undefined || (showRiskConfirmation && modalGeneralToSKey.value)) {
      return resolveGeneralToS();
    }

    return new Promise<void>((resolve) => {
      modalGeneralToSKey.value = modalService.show(
        shallowRef(GeneralToSModal),
        {
          accept: () => {
            settingsService.setSettings({
              path: 'riskAgreement',
              value: new Date().toString(),
            });
            hideGeneralToSModal();
            resolveGeneralToS();
            resolve();
          },
        },
        {},
        { canClose: false },
      );
    });
  };
  const handleGeneralToS = () => {
    showGeneralToSModalPromise = showGeneralToSModalPromise.then(showGeneralToSModal);

    return showGeneralToSModalPromise;
  };

  const resolveTermAndConditionsModal = () => {
    showTermAndConditionsModalPromise = Promise.resolve();

    return showTermAndConditionsModalPromise;
  };
  const hideTermAndConditionsModal = () => {
    if (modalTermAndConditionsKey.value) {
      modalService.hide(modalTermAndConditionsKey.value);
      modalTermAndConditionsKey.value = undefined;
    }
  };
  const showTermAndConditionsModal = async () => {
    const { showTermAndConditions } = settingsService.complianceInfo;

    if (showTermAndConditions === false) {
      if (modalTermAndConditionsKey.value) {
        hideTermAndConditionsModal();
      }

      return resolveTermAndConditionsModal();
    }

    if (shouldTermAndConditionsNotBeShown.value || showTermAndConditions === undefined || (showTermAndConditions && modalTermAndConditionsKey.value)) {
      return resolveTermAndConditionsModal();
    }

    const accept = async () => {
      await settingsService.setSettings({
        path: 'termAndConditions042024',
        value: new Date().toString(),
      });

      // TODO also add to Dynamo
    };

    return new Promise<void>((resolve) => {
      modalTermAndConditionsKey.value = modalService.show(
        shallowRef(TermsAndConditionsModal),
        {
          accept: async () => {
            try {
              accept();
              hideTermAndConditionsModal();
              resolveTermAndConditionsModal();
              resolve();
            } catch (e) {
              logger.error('Accepting T&C failed: ', e);
            }
          },
        },
        {},
        { canClose: false },
      );
    });
  };
  const handleTermAndConditionsModal = () => {
    showTermAndConditionsModalPromise = showTermAndConditionsModalPromise.then(showTermAndConditionsModal);

    return showTermAndConditionsModalPromise;
  };

  watchEffect(async () => {
    if (checkIsBiometricRoute(route)) {
      return;
    }

    if ((settingsService.complianceInfo.showTermAndConditions && !shouldTermAndConditionsNotBeShown.value) || settingsService.complianceInfo.showRiskConfirmation) {
      await handleTermAndConditionsModal();
      await handleGeneralToS();
    }

    if (settingsService.complianceInfo.showTermAndConditions === false) {
      resolveTermAndConditionsModal();
      hideTermAndConditionsModal();
    }

    if (settingsService.complianceInfo.showRiskConfirmation === false) {
      resolveGeneralToS();
      hideGeneralToSModal();
    }
  });
};

export default useGlobalConfirmationModals;
