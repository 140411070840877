<script lang="ts">
export default {
  name: 'TermsAndConditionsModal',
  customOptions: {},
};
</script>

<script lang="ts" setup>
import { type PropType } from 'vue';

import usePromise from '@exchange/libs/composables/shared/src/lib/usePromise';
import { modalVariant, type ModalVariant } from '@exchange/libs/modals/src';

const props = defineProps({
  accept: { type: Function as PropType<() => Promise<void>>, required: true },
  variant: { type: String as PropType<ModalVariant>, default: modalVariant.dark },
});

const { loading: accepting, createPromise: acceptNow } = usePromise(props.accept);
</script>

<template>
  <x-modal
    class="terms-and-conditions-modal"
    :can-close="false"
    :wide="true"
    :variant="variant"
    :title="$t('ui.termsAndConditions.title')"
  >
    <!-- Content -->
    <template #content>
      <i18n-t
        class="terms-and-conditions-modal__content"
        tag="p"
        keypath="ui.termsAndConditions.disclaimer"
      >
        <template #linkTermsAndConditions>
          <x-link-external
            :href="$t('ui.termsAndConditions.disclaimerLinks.linkTermsAndConditions.link')"
            :use-system-browser="true"
          >
            {{ $t('ui.termsAndConditions.disclaimerLinks.linkTermsAndConditions.text') }}
          </x-link-external>
        </template>
        <template #linkSupport>
          <x-link-external
            :href="$t('ui.termsAndConditions.disclaimerLinks.linkSupport.link')"
            :use-system-browser="true"
          >
            {{ $t('ui.termsAndConditions.disclaimerLinks.linkSupport.text') }}
          </x-link-external>
        </template>
      </i18n-t>
    </template>

    <!-- Actions -->
    <template #buttons>
      <x-button
        testid="accept-terms-and-conditions"
        variant="primary"
        :loading="accepting"
        :disabled="accepting"
        @click="acceptNow"
      >
        {{ $t('ui.termsAndConditions.agree') }}
      </x-button>
    </template>
  </x-modal>
</template>
